<template>
  <div
    :class="{
      'prose dark:prose-invert max-w-none': true,
      'prose-h1:text-3xl': true,
      'prose-pre:text-gray-800 dark:prose-pre:text-gray-200': true,
      'prose-pre:bg-gray-100 dark:prose-pre:bg-gray-800': true,
      'prose-code:break-all': true,
      'prose-code:font-normal': true,
      'prose-code:text-current': true,
     'prose-code:text-gray-800 dark:prose-code:text-gray-200' : true,
      'prose-code:bg-gray-100 dark:prose-code:bg-gray-800': true,
      'prose-code:py-0.5 prose-code:px-1': true,
      'prose-code:border prose-code:border-gray-200 dark:prose-code:border-gray-700': true,
      'prose-code:rounded': true,
      'prose-a:text-blue-600 prose-a:dark:text-blue-400': true,
      'first:prose-td:pl-2 last:prose-td:pr-2': true,
      'first:prose-th:pl-2 last:prose-th:pr-2': true,
    }"
  >
    <slot />
  </div>
</template>

<style lang="postcss">
  .prose :where(code):not(:where([class~='not-prose'] *)) {
    &::before,
    &::after {
      content: '';
    }
  }

  .prose pre code {
    border: 0;
    padding: 0;
  }
</style>
